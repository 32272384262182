import { Pie } from "react-chartjs-2";
import { Skeleton } from "@mui/material";
import { PiPackage } from "react-icons/pi";
import { RiProgress5Line } from "react-icons/ri";
import { BiCalendarCheck } from "react-icons/bi";
import { TbTruckDelivery } from "react-icons/tb";
import { VscServerProcess } from "react-icons/vsc";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import React, { useState, useEffect, useCallback } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import AllOrders from "../components/AllOrders";
import { getAllOrders, getOrdersByStatus } from "../api";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allOrders, setAllOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [ordersByStatus, setOrdersByStatus] = useState([]);

  // ************** FETCH ALL ORDERS ***************

  const fetchAllOrders = useCallback(async (currentPage) => {
    try {
      const data = await getAllOrders(currentPage + 1, 100);

      const sortedOrders = data.orders.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setAllOrders(sortedOrders);

      // console.log(sortedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    } finally {
      setLoadingOrders(false);
    }
  }, []);

  useEffect(() => {
    fetchAllOrders(page);
  }, [fetchAllOrders, page]);

  // ************** FETCH ORDERS BY STATUS ***************

  const fetchOrdersByStatus = async () => {
    try {
      const response = await getOrdersByStatus();
      setOrdersByStatus(response?.data || {});
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersByStatus();
  }, []);

  // ************** TOTAL PAGES ***************

  const totalPages = Math.ceil(ordersByStatus?.total_orders / 100);

  // ************** ORDERS STATUS ***************

  const orderStatus = [
    {
      id: 0,
      title: "Booked orders",
      number: ordersByStatus?.booked || 0,
      icon: <BiCalendarCheck size={35} className="text-secondary" />,
    },
    {
      id: 1,
      title: "Orders in process",
      number: ordersByStatus?.in_process || 0,
      icon: <RiProgress5Line size={35} className="text-secondary" />,
    },
    {
      id: 2,
      title: "Finishing Orders",
      number: ordersByStatus?.finishing || 0,
      icon: <VscServerProcess size={35} className="text-secondary" />,
    },
  ];

  const orderStatus2 = [
    {
      id: 0,
      title: "Orders scheduled for pickup",
      number: ordersByStatus?.scheduled_for_pickup || 0,
      icon: <TbTruckDelivery size={35} className="text-secondary" />,
    },
    {
      id: 1,
      title: "Orders arrived at Filato",
      number: ordersByStatus?.arrived_at_filato || 0,
      icon: <HiOutlineOfficeBuilding size={35} className="text-secondary" />,
    },
  ];

  // ************** CHART DATA ***************

  const chartData = {
    labels: ["Booked", "In Process", "Finishing"],
    datasets: [
      {
        label: "Order Status",
        data: [
          ordersByStatus?.booked || 0,
          ordersByStatus?.in_process || 0,
          ordersByStatus?.finishing || 0,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  return (
    <>
      {loading ? (
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
          <div className="flex flex-col gap-2 lg:col-span-3">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5"
                  >
                    <div className="flex flex-col gap-2">
                      <Skeleton
                        height={20}
                        width={60}
                        animation="wave"
                        variant="rounded"
                      />
                      <Skeleton
                        height={15}
                        width={150}
                        animation="wave"
                        variant="rounded"
                      />
                    </div>

                    <Skeleton
                      height={50}
                      width={50}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                ))}
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
              {Array(2)
                .fill(null)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5"
                  >
                    <div className="flex flex-col gap-2">
                      <Skeleton
                        height={30}
                        width={30}
                        animation="wave"
                        variant="circular"
                      />
                      <Skeleton
                        height={20}
                        width={60}
                        animation="wave"
                        variant="rounded"
                      />
                      <Skeleton
                        height={15}
                        width={150}
                        animation="wave"
                        variant="rounded"
                      />
                    </div>

                    <Skeleton
                      height={50}
                      width={50}
                      animation="wave"
                      variant="circular"
                    />
                  </div>
                ))}
            </div>

            <div className="flex flex-col bg-white rounded-lg border border-neutral p-5 gap-4">
              <Skeleton width={150} animation="wave" variant="rounded" />

              <div className="flex flex-col bg-light animate-pulse rounded">
                <div className="flex items-center gap-4 justify-between bg-neutral p-4 rounded">
                  {Array(5)
                    .fill(null)
                    .map((_, index) => (
                      <Skeleton
                        key={index}
                        width={150}
                        height={40}
                        variant="text"
                        animation="wave"
                      />
                    ))}
                </div>

                <div className="flex flex-col p-4">
                  {Array(4)
                    .fill(null)
                    .map((_, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-4 justify-between"
                      >
                        <Skeleton
                          width={25}
                          height={40}
                          variant="text"
                          animation="wave"
                        />
                        {Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <Skeleton
                              key={index}
                              width={150}
                              height={40}
                              variant="text"
                              animation="wave"
                            />
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-4 bg-white rounded-lg border border-neutral p-5">
              <Skeleton
                height={55}
                width={55}
                animation="wave"
                variant="circular"
              />
              <div className="flex flex-col gap-1">
                <Skeleton
                  height={20}
                  width={100}
                  animation="wave"
                  variant="rounded"
                />

                <Skeleton width={70} animation="wave" variant="text" />
              </div>
            </div>

            <div className="flex items-center gap-4 bg-white rounded-lg border border-neutral p-5">
              <div className="flex flex-col">
                <Skeleton width={100} animation="wave" variant="text" />
                <Skeleton width={70} animation="wave" variant="text" />
              </div>
            </div>

            <div className="flex flex-col items-center gap-4 bg-white rounded-lg border border-neutral p-5">
              <Skeleton
                width={120}
                animation="wave"
                variant="rounded"
                className="self-start"
              />

              <div className="flex flex-col items-center">
                <div className="flex items-center gap-2">
                  <Skeleton width={70} animation="wave" variant="text" />
                  <Skeleton width={70} animation="wave" variant="text" />
                </div>

                <Skeleton width={90} animation="wave" variant="text" />
              </div>

              <Skeleton
                width={150}
                height={150}
                animation="wave"
                variant="circular"
              />

              <Skeleton width={120} animation="wave" variant="text" />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
            <div className="flex flex-col gap-1 lg:col-span-3">
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
                {orderStatus?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5 gap-1"
                  >
                    <div className="flex flex-col gap-1">
                      <span className="font-semibold text-2xl">
                        {item.number}
                      </span>
                      <span className="text-secondary">{item.title}</span>
                    </div>

                    <span>{item.icon}</span>
                  </div>
                ))}
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
                {orderStatus2?.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white rounded-lg border border-neutral p-5 gap-1"
                  >
                    <div className="flex flex-col gap-1">
                      <span className="font-semibold text-2xl">
                        {item.number}
                      </span>
                      <span className="text-secondary">{item.title}</span>
                    </div>

                    <span>{item.icon}</span>
                  </div>
                ))}
              </div>

              <AllOrders
                setPage={setPage}
                orders={allOrders}
                currentPage={page}
                totalPages={totalPages}
                loadingOrders={loadingOrders}
                fetchAllOrders={fetchAllOrders}
                fetchOrdersByStatus={fetchOrdersByStatus}
                totalOrders={ordersByStatus?.total_orders}
              />
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex bg-white rounded-lg border border-neutral px-5 py-5 gap-3 items-center">
                <span className="p-4 bg-light border rounded-full">
                  <PiPackage size={30} />
                </span>

                <div className="flex flex-col">
                  <span className="font-bold text-xl">Total Orders</span>
                  <span className="text-secondary font-medium text-lg">
                    {ordersByStatus?.total_orders || 0}
                  </span>
                </div>
              </div>

              <div className="flex flex-col bg-white rounded-lg border border-neutral px-5 py-5">
                <div className="flex items-center gap-2">
                  <span className="text-secondary font-medium text-2xl">
                    {ordersByStatus?.out_for_delivery || 0}
                  </span>
                  <span className="font-medium">orders</span>
                </div>
                <span className="font-medium">are out for delivery</span>
              </div>

              <div className="flex flex-col gap-3 bg-white rounded-lg border border-neutral p-5">
                <h3 className="text-lg font-bold">Overview</h3>
                <Pie data={chartData} />

                <div className="flex items-center gap-2 self-center mt-3">
                  <span className="text-xl font-medium text-secondary">
                    {ordersByStatus?.delivered || 0}
                  </span>
                  <span>Completed Orders</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
