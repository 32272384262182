import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/login";
import Tailors from "./pages/tailors";
import Layout from "./components/Layout";
import Dashboard from "./pages/dashboard";
import OrderDetail from "./pages/orderDetail";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
            }
          />
          <Route
            path="/order/:id"
            element={
              <ProtectedRoute
                element={
                  <Layout>
                    <OrderDetail />
                  </Layout>
                }
              />
            }
          />
          <Route
            path="/tailors"
            element={
              <ProtectedRoute
                element={
                  <Layout>
                    <Tailors />
                  </Layout>
                }
              />
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
