import {
  List,
  Menu,
  Toolbar,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import React, { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { FaAngleLeft } from "react-icons/fa6";
import { GiSewingMachine } from "react-icons/gi";
import { MdSpaceDashboard } from "react-icons/md";
import { PiUserCircleLight } from "react-icons/pi";
import { IoMenu, IoLogOutOutline } from "react-icons/io5";
import { useNavigate, useLocation } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";

import { useAuth } from "../context/AuthContext";

// ************** DRAWER ***************

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function Layout({ children }) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  // ************** DRAWER ***************

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //************ OPEN NOTIFICATION *********** */

  const [openNotifications, setOpenNotifications] = useState(null);
  const openNotification = Boolean(openNotifications);

  const handleOpenNotifications = (event) => {
    setOpenNotifications(event.currentTarget);
  };

  //************ CLOSE NOTIFICATION *********** */

  const handleCloseNotifications = () => {
    setOpenNotifications(null);
  };

  //************ OPEN ADMIN PROFILE *********** */

  const [openAdmin, setOpenAdmin] = useState(null);
  const openAdminMenu = Boolean(openAdmin);

  const handleOpenAdmin = (event) => {
    setOpenAdmin(event.currentTarget);
  };

  //************ CLOSE ADMIN PROFILE *********** */

  const handleCloseAdmin = () => {
    setOpenAdmin(null);
  };

  // ************** LOGOUT ***************

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // ************** SIDE MENU ***************

  const menuItems = [
    {
      text: "Dashboard",
      icon: <MdSpaceDashboard size={21} />,
      route: "/",
    },
    { text: "Tailors", icon: <GiSewingMachine size={22} />, route: "/tailors" },
  ];

  const currentMenuItem = menuItems.find(
    (item) =>
      item.route === location.pathname ||
      // item.route === "/order" &&
      location.pathname.startsWith("/order/")
  );

  const isOrderDetail = /^\/order\/[a-f0-9]{24}$/.test(location.pathname);

  return (
    <div className="flex">
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "white" }}>
        <Toolbar className="bg-white text-primary gap-10 flex items-center justify-between">
          <div className="flex items-center gap-8">
            <IoMenu
              size={25}
              onClick={handleDrawerOpen}
              className={`${open && "hidden"} cursor-pointer`}
            />
            <span className="text-2xl font-medium uppercase">
              {isOrderDetail
                ? "Order Detail"
                : currentMenuItem
                ? currentMenuItem.text
                : "Orders"}
            </span>
          </div>

          <div className="flex items-center gap-5 hover:cursor-pointer">
            <IoNotificationsOutline
              size={22}
              onClick={handleOpenNotifications}
            />
            <Menu
              open={openNotification}
              anchorEl={openNotifications}
              onClose={handleCloseNotifications}
              PaperProps={{
                elevation: 4,
                sx: {
                  width: "100%",
                  maxWidth: "25vw",
                  marginTop: "10px",
                },
              }}
            >
              <div className="flex flex-col px-5 py-2 gap-2">
                <span className="font-medium text-lg">Notifications</span>
                {/* <span>Zunaira Asif placed a new order.</span> */}
                <span>Coming Soon...</span>
              </div>
            </Menu>

            <PiUserCircleLight size={27} onClick={handleOpenAdmin} />
            <Menu
              open={openAdminMenu}
              anchorEl={openAdmin}
              onClose={handleCloseAdmin}
              PaperProps={{
                elevation: 4,
                sx: {
                  marginTop: "10px",
                },
              }}
            >
              <div className="flex flex-col px-5 py-2 gap-3">
                <button
                  onClick={handleLogout}
                  className="flex gap-3 items-center text-red"
                >
                  <IoLogOutOutline size={22} color="red" />
                  <span className="xl:text-lg text-base">Logout</span>
                </button>
              </div>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <div className="bg-grey h-full text-white flex flex-col">
          <div className="flex items-center my-3 px-5 justify-between">
            <span className={`text-4xl font-semibold}`}>FILÀTO</span>

            <div className="flex justify-self-end rounded-full p-2 hover:bg-secondary">
              <FaAngleLeft onClick={handleDrawerClose} />
            </div>
          </div>

          <Divider className="bg-secondary" />

          <List>
            {menuItems.map((item, index) => (
              <ListItem
                key={item.text}
                disablePadding
                sx={{
                  display: "block",
                  backgroundColor:
                    item.text === currentMenuItem.text ? "#49535a" : "",
                }}
              >
                <ListItemButton
                  onClick={() => navigate(item.route)}
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                    },
                    open
                      ? {
                          justifyContent: "initial",
                        }
                      : {
                          justifyContent: "center",
                        },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: "center",
                      },
                      open
                        ? {
                            mr: 2,
                          }
                        : {
                            mr: "auto",
                          },
                    ]}
                  >
                    {index % 2 === 0 ? (
                      <MdSpaceDashboard size={21} className="text-white" />
                    ) : (
                      <GiSewingMachine size={22} className="text-white" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={[
                      open
                        ? {
                            opacity: 1,
                          }
                        : {
                            opacity: 0,
                          },
                    ]}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

      <div className="flex-1 bg-light p-4 h-full min-h-screen">
        <DrawerHeader />
        {children}
      </div>
    </div>
  );
}
